<template>
  <div class="news_whole">
    <Nav></Nav>
    <div class="news-con minheight">
      <div class="news-title">
        <div class="new-text">
          <div class="title"></div>
          <div class="text">{{ titleInfo.title }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="news-list">
        <div
          v-for="post in navList"
          :key="post.id"
          class="whole_box"
          v-bind:class="{
            banmian_box: banmian,
          }"
        >
          <div class="whole_box_content">
            <div v-if="post.postType == 'PIC'" class="img" :style="{ backgroundImage: `url(` + post.imgURL + `)` }" @click="onLookPost(post.id)"></div>
            <div v-if="post.postType == 'VIDEO'" class="video" @click="onLookPost(post.id)">
              <div class="video-item" @click="onLookPost(post.id)">
                <video
                  :src="post.videoURL"
                  :poster="post.posterURL"
                  :ref="'video_' + post.id"
                  @mouseenter="onVideoEnter('video_' + post.id)"
                  @mouseleave="onVideoLeave('video_' + post.id)"
                  loop
                  :muted="post.mute"
                ></video
                ><i
                  class="mute el-icon-headset active"
                  v-if="post.mute"
                  @mouseenter="onVideoEnter('video_' + post.id)"
                  @mouseleave="onVideoLeave('video_' + post.id)"
                  @click.stop="post.mute = !post.mute"
                ></i>
                <i
                  class="mute el-icon-headset"
                  v-if="!post.mute"
                  @mouseenter="onVideoEnter('video_' + post.id)"
                  @mouseleave="onVideoLeave('video_' + post.id)"
                  @click.stop="post.mute = !post.mute"
                ></i>
              </div>
            </div>
            <div class="title_s" @click="onLookPost(post.id)">
              {{ post.title }}
            </div>
            <div class="eventTime">{{ post.eventTime }}</div>
          </div>
        </div>
      </div>
      <div class="news-bottom">
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="20"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="downTotal"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      navList: [], // 二级的稿件
      titleInfo: {},
      navName: '', // 稿件所属的Nav
      page: 1,
      currentPage: 1,
      downTotal: 1,
      banmian: false, // 版面展示
      fixtopicTitle: '',
    }
  },
  created() {
    console.log('ds1', this.$route.query)
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    onVideoEnter(ref) {
      this.$refs[ref][0].play()
    },
    onVideoLeave(ref) {
      this.$refs[ref][0].pause()
    },
    reload() {
      var thiz = this
      if (this.$route.query.id == 20) {
        thiz.banmian = true
      }
      if (this.$route.query.name) {
        var query = {}
        var page = 1
        query.addrId = this.$route.query.id
        query.storeIds = [1]
        RD.pure()
          .post()
          .query(query, page, 10)
          .then((items) => {
            items.list.forEach((k) => {
              if (k.postType == 'PIC') {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              }
              if (k.postType == 'VIDEO') {
                k.videoURL = RD.video().id(k.id).svideo_URL()
                k.posterURL = RD.video().id(k.id).poster_URL()
                k.mute = true
              }
            })
            thiz.navList = items.list
            thiz.titleInfo.title = this.$route.query.name
            thiz.currentPage = items.pager.currentPage
            thiz.downTotal = items.pager.total
          })
      } else {
        RD.fixedTopic()
          .id(this.$route.query.id)
          .posts(thiz.page, 20)
          .then((data) => {
            console.log('datamx', data)
            data.list.forEach((k) => {
              if (k.postType == 'PIC') {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              }
              if (k.postType == 'VIDEO') {
                k.videoURL = RD.video().id(k.id).svideo_URL()
                k.posterURL = RD.video().id(k.id).poster_URL()
                k.mute = true
              }
            })
            thiz.navList = data.list
            thiz.titleInfo = data.item
            thiz.currentPage = data.pager.currentPage
            thiz.downTotal = data.pager.total
          })
      }
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      thiz.reload(thiz.page)
    },
    // 查看稿件
    onLookPost(id) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: id },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.news_whole {
  background-color: #fff;
}

@media all and (min-width: 801px) {
  .news-con {
    width: 120rem;
    min-height: calc(100vh - 25.9rem);
    margin: 0 auto;
    .news-title {
      height: 7rem;
      width: 100%;
      .new-text {
        width: 50%;
        text-align: left;
        margin-left: 0.5rem;
        font-size: 1.4rem;
        display: flex;
        padding-top: 1rem;
        .title {
          width: 5rem;
          height: 5rem;
          background: no-repeat url(../assets/carame.png);
          background-size: cover;
        }
        .text {
          margin-left: 0.5rem;
          font-size: 1.8rem;
          line-height: 5rem;
        }
      }
      .line {
        width: 100%;
        height: 0.2rem;
        margin-top: 1rem;
        margin-left: 0.5rem;
        background-color: #bfbfbf;
      }
    }
    .news-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      margin-top: 2rem;
      .whole_box {
        height: 28rem;
        width: 28rem;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin: 1rem;
        .whole_box_content {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .img {
            width: 100%;
            height: 75%;
            transition: all 0.6s;
            display: block;
            z-index: 10;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #f6f6f6;
            cursor: pointer;
          }
          .video {
            width: 100%;
            height: 75%;
            transition: all 0.6s;
            display: block;
            z-index: 10;
            .video-item {
              width: 100%;
              height: 100%;
              position: relative;
              video {
                width: 100%;
                height: 100%;
                background-color: #ccc;
                cursor: pointer;
              }
              .mute {
                display: block;
                position: absolute;
                right: 2rem;
                top: 2rem;
                font-size: 2rem;
                height: 3rem;
                width: 3rem;
                border: 2px solid white;
                border-radius: 2rem;
                line-height: 3rem;
                color: white;
              }
              .mute.active {
                color: red;
              }
            }
          }
          .title_s {
            height: 8%;
            transition: all 0.5s linear;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 1.5rem;
            text-align: left;
            font-size: 14px;
            left: 0;
            right: 0;
            margin-left: 1rem;
            line-height: 2.5rem;
            cursor: pointer;
          }
          .eventTime {
            height: 10%;
            text-align: left;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .time {
            height: 10%;
            position: absolute;
            left: 13.5rem;
            .count {
              height: 2rem;
              display: inline-block;
            }
            .tag {
              display: inline-block;
              height: 2rem;
              width: 2rem;
              cursor: pointer;
              margin-left: 1rem;
              background: no-repeat url(../assets/tagup.png);
            }
            .like {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              background: no-repeat url(../assets/like.png);
            }
            .com {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              background: no-repeat url(../assets/comment.png);
            }
          }
        }
      }
      .banmian_box {
        height: 48.5rem;
        .whole_box_content {
          .img {
            height: 82%;
            background-size: contain;
          }
        }
      }
    }
    .news-bottom {
      height: 8rem;
      width: 100%;
      margin-top: 4rem;
      position: relative;
      .page-container {
        position: absolute;
        right: 5rem;
      }
    }
  }
}
@media all and (max-width: 800px) {
  .news-con {
    width: 750px;
    height: 100%;
    margin: 0 auto;
    .news-title {
      height: 50px;
      width: 100%;
      .new-text {
        width: 50%;
        text-align: left;
        margin-left: 0.5rem;
        font-size: 14px;
        display: flex;
        padding-top: 1rem;
        .title {
          width: 32px;
          height: 32px;
          background: no-repeat url(../assets/carame.png);
          background-size: cover;
        }
        .text {
          margin-left: 5px;
          font-size: 16px;
          line-height: 32px;
        }
      }
      .line {
        width: 100%;
        height: 0.2rem;
        margin-top: 1rem;
        margin-left: 0.5rem;
        background-color: #bfbfbf;
      }
    }
    .news-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      margin-top: 20px;
      .whole_box {
        height: 170px;
        width: 170px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin: 1rem;
        .whole_box_content {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .img {
            width: 100%;
            height: 75%;
            transition: all 0.6s;
            display: block;
            z-index: 10;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #f6f6f6;
            cursor: pointer;
          }
          .title_s {
            height: 8%;
            transition: all 0.5s linear;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 1.5rem;
            text-align: left;
            font-size: 1.4rem;
            left: 0;
            right: 0;
            margin-left: 1rem;
            line-height: 2.5rem;
            cursor: pointer;
          }
          .eventTime {
            height: 10%;
            text-align: left;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .time {
            height: 10%;
            position: absolute;
            left: 13.5rem;
            .count {
              height: 2rem;
              display: inline-block;
            }
            .tag {
              display: inline-block;
              height: 2rem;
              width: 2rem;
              cursor: pointer;
              margin-left: 1rem;
              background: no-repeat url(../assets/tagup.png);
            }
            .like {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              background: no-repeat url(../assets/like.png);
            }
            .com {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              background: no-repeat url(../assets/comment.png);
            }
          }
        }
      }
      .banmian_box {
        height: 48.5rem;
        .whole_box_content {
          .img {
            height: 82%;
            background-size: contain;
          }
        }
      }
    }
    .news-bottom {
      height: 8rem;
      width: 100%;
      margin-top: 4rem;
      position: relative;
      .page-container {
        position: absolute;
        right: 5rem;
      }
    }
  }
}
</style>
